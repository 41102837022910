<template>
  <b-row>
    <b-col cols="0" lg="4"></b-col>
    <b-col cols="12" lg="4">
      <h3>Enter your new password below</h3>
      <b-form-group
          id="input-group-password"
          label="New Password"
          label-for="input-password"
      >
        <b-form-input
            id="input-password"
            type="password"
            v-model="password"
            v-on:keyup.enter="reset"
        ></b-form-input>
      </b-form-group>
      <b-button block variant="primary" @click="reset">Submit</b-button>
    </b-col>
    <b-col cols="0" lg="4"></b-col>
  </b-row>

</template>

<script>
import http from "../http-common"
export default {
  name: "ResetPassword",
  data: function () {
    return {
      password: '',
    };
  },
  methods: {
    reset() {
      console.log(this.$route.params);
      http.post('user/reset-password', {}, {
        params: {
          reset_key: this.$route.query.reset_key,
          new_password: this.password,
        }
      })
      .then((response) => {
        this.$emit('login-complete', response.data);
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
    }
  },
}
</script>

<style scoped>

</style>